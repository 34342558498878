import { Controller } from "@hotwired/stimulus"
// import { TweenLite} from 'gsap';

export default class extends Controller {
  connect() {
    // console.log("its")
    this.element.scrollIntoView({block: 'start',  behavior: 'smooth'})
    // TweenLite.to(this.element, {duration: 2, scrollTo: 250});
   
  }
}