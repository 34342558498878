// Entry point for the build script in your package.json
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

//Turbo
import "@hotwired/turbo-rails"

// Stimulus controllers
import "./controllers"

// ActionCable Channels
import "./channels"




// import { Application } from "@hotwired/stimulus"
// const application = Application.start()
//
// // Configure Stimulus development experience
// application.debug = false
// window.Stimulus = application
// export { application }
//
// import DelegateClickController from "./controllers/delegate_click_controller"
// application.register("delegate_click", DelegateClickController)
//
// import RevealController from "./controllers/reveal_controller"
// application.register("reveal", RevealController)


// Stimulus.handleError = (error, message, detail) => {
//   console.warn(message, detail)
//   ErrorTrackingSystem.captureException(error)
// }




// https://github.com/BennyAlex/material-design-inspired-color-picker
// import 'material-design-inspired-color-picker'

document.addEventListener("turbo:load", () => {
  // const select = (e) => document.querySelector(e);
  // const selectAll = (e) => document.querySelectorAll(e);
  
  //Checkout
  document.addEventListener('input', function (event) {
    // Only run on our select menu
    if (event.target.name !== 'order[shipping_method_id]') return;
    var elem_shipping = document.querySelector('#shipping');    
    var elem = document.querySelector('#total');  
      
    var total = event.target.dataset.price;
    var shipping = event.target.dataset.shipping;
    
    elem_shipping.textContent = shipping;
    elem.textContent = total;

  }, false);
  
  
  
  //Expand textarea
  var autoExpand = function (field) {

  	// Reset field height
  	field.style.height = 'inherit';

  	// Get the computed styles for the element
  	var computed = window.getComputedStyle(field);

  	// Calculate the height
  	var height = parseInt(computed.getPropertyValue('border-top-width'), 10)
  	             + parseInt(computed.getPropertyValue('padding-top'), 10)
  	             + field.scrollHeight
  	             + parseInt(computed.getPropertyValue('padding-bottom'), 10)
  	             + parseInt(computed.getPropertyValue('border-bottom-width'), 10);

  	field.style.height = height + 'px';
  };

  document.addEventListener('input', function (event) {
  	if (event.target.tagName.toLowerCase() !== 'textarea') return;
  	autoExpand(event.target);
  }, false);

  //Display File Name
  var input = document.getElementById( 'file-upload' );
  var infoArea = document.getElementById( 'file-upload-name' );

  function showFileName( event ) {
    // the change event gives us the input it occurred in
    var input = event.srcElement;

    // the input has an array of files in the `files` property, each one has a name that you can use. We're just using the name here.
    var fileName = input.files[0].name;

    // use fileName however fits your app best, i.e. add it into a div
    infoArea.textContent = 'File name: ' + fileName;
  }

  if(input){
    input.addEventListener( 'change', showFileName );
  }


  // Preview image
  const reader = new FileReader();
  const fileInput = document.getElementById("file-upload");
  const img = document.getElementById("img");
  reader.onload = e => {
    img.src = e.target.result;
  }

  var _PREVIEW_URL;

  if(fileInput){
    fileInput.addEventListener('change', e => {
      // const f = e.target.files[0];
      // reader.readAsDataURL(f);

      var file = e.target.files[0];

     // allowed MIME types
     var mime_types = [ 'image/jpeg', 'image/png' ];

     // validate MIME
     if(mime_types.indexOf(file.type) == -1) {
         alert('Error : Incorrect file type');
         return;
     }

     // validate file size
     if(file.size > 8*1024*1024) {
         alert('Error : Exceeded size 8MB');
         return;
     }

     // validation is successful

     // hide upload dialog button
     //document.querySelector("#upload-dialog").style.display = 'none';

     // object url
     _PREVIEW_URL = URL.createObjectURL(file);

     // set src of image and show
     document.querySelector("#preview-image").setAttribute('src', _PREVIEW_URL);
     document.querySelector("#preview-image").style.display = 'inline-block';
    })

  }

})


