// https://github.com/excid3/tailwindcss-stimulus-components

import { Controller } from "@hotwired/stimulus"
import formRequestSubmitPolyfill from "./../src/form-request-submit-polyfill"

export default class extends Controller {
  static targets = ['form', 'status']

  connect() {
    this.timeout = 500
    this.duration = this.data.get('duration') || 500
  }

  save() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      // this.statusTarget.textContent = 'Saving...'
      // Rails.fire(this.formTarget, 'submit')
      this.element.requestSubmit()
      
    }, this.duration)
  }

  success() {
    this.setStatus('Saved!')
  }

  error() {
    this.setStatus('Unable to save!')
  }

  setStatus(message) {
    this.statusTarget.textContent = message

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = ''
    }, 2000)
  }
}