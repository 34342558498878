// import Rails from "@rails/ujs"
import { Controller } from "@hotwired/stimulus"
import { patch } from '@rails/request.js'

//Import Redactor editor
import './../src/redactorx.min.js'
import './../src/alignment/alignment.js'

export default class extends Controller {
  
  static targets = [ "output" ]

  connect() {
    var contentTarget =  this.element    
    var url = this.data.get("url")
    var model = this.data.get("model")
    var placeholder = this.data.get("placeholder") || "Begin met typen"
    let formData = new FormData()

    var timeout  = 0
    var duration = 1000
    
    RedactorX(this.element, { 
      plugins: ['alignment'],
      alignment: {
              align: {
                  left: 'text-left',
                  center: 'text-center',
                  right: 'text-right',
                  justify: false
              }
      },
      minHeight: '50px',
      // control: true,
      //   context: true,
      //   toolbar: false,
      //   topbar: false,  
      // addbar: ['text'],
      placeholder: placeholder,           
      
      classes: {
        'ul': 'list-disc ml-6',
        'ol': 'list-decimal ml-6'
      },
      
      
      formatAdd: {
        "Paragraf 2": {
          title: 'Paragraph 2',
          type: 'paragraph',
          params: {
            tag: 'p',
            classname: 'text-p2'
          }
        },
        "Paragraf 3": {
          title: 'Paragraph 3',
          type: 'paragraph',
          params: {
            tag: 'p',
            classname: 'text-p3'
          }
        },
      },

      subscribe: {
        'editor.change': function(event) {
          //Clear TimeOut
          clearTimeout(timeout)
          //Timeout to prevent saving too often
          timeout = setTimeout(() => {
            formData.append(model, contentTarget.value )
              
            const response =  patch(url, { 
              responseKind: "turbo-stream",
              // responseKind: "json",
              body: formData
            })  
              

          }, duration)
        }
      }

    });
    
                         
  }

}
