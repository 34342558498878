import { Controller } from "@hotwired/stimulus"
import Pickr from "@simonwep/pickr";


export default class extends Controller {
  static targets = ["picker", "input"]

  initialize() {
    this.initPicker();
    console.log("active")
  }

  initPicker() {
    this.picker = Pickr.create({
      el: this.pickerTarget,
      theme: 'nano',
      default: this.inputTarget.value,

      components: {
        preview: true,
        opacity: false,
        hue: true,

        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: true,
        },
      },
    });

    this.picker.on('save', (color, _instance) => {
      this.inputTarget.value = color.toHEXA().toString();
      this.inputTarget.dispatchEvent(new Event('change'));
      this.picker.hide();
      
      var event = new Event('keyup');      
      this.inputTarget.dispatchEvent(event);
      
      // document.getElementById('scaled-frame').contentWindow.document.documentElement.style.setProperty('--brand-color',color.toHEXA().toString())
      
    });
  }
  
  setColor(event){
    event.preventDefault()
    const value = event.target.dataset.value     
    this.picker.setColor(value)
    // console.log(this.picker.getColor())
  }
}